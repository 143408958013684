import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Layout = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="min-h-screen flex flex-col">
      <div className="grid grid-cols-1 grid-rows-1 min-h-screen">
        <div
          className="flex flex-col items-center"
          style={{
            background: "#E5E5E5",
          }}
        >
          {children}
          <div className="text-grey-0 inter-base-regular pb-2">
            <span>&#183;</span>{" "}
            <a
              style={{ color: "#888888", textDecoration: "none" }}
              href="mailto:hello@mrfood.com"
            >
              Copyright © ミスターフード All Rights Reserved.
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
